<template>
  <div class="animated fadeIn">
    <b-card :title="$t('message.title.product')">
      <div class="wrapper">
        <div style="top: 10px">
          <b-form @submit.prevent="searchFn">
            <div class="row">
              <div class="col-sm-6 col-md-3 col-lg-3 col-xl-2 my-1">
                {{ $t("message.productId") }} :
                <br />
                <b-form-input v-model="searchData.productId" placeholder="ex 1,2,..." />
              </div>
              <div class="col-sm-6 col-md-3 col-lg-3 col-xl-2 my-1">
                {{ $t("message.sku") }} :
                <br />
                <b-form-input v-model="searchData.sku" />
              </div>
              <div class="col-sm-6 col-md-3 col-lg-3 col-xl-2 my-1">
                {{ $t("message.productName") }} :
                <br />
                <b-form-input v-model="searchData.name" />
              </div>
              <div class="col-sm-6 col-md-3 col-lg-3 col-xl-2 my-1">
                {{ $t("message.productType") }} :
                <br />
                <b-form-select v-model="searchData.productType" :options="productType" text-field="name" value-field="value" />
              </div>
            </div>
            <div class="row">
              <div class="col-sm-6 col-md-3 col-lg-3 col-xl-2 my-1">
                {{ $t("message.cost") }} :
                <br />
                <b-form-input v-model="searchData.stdPrice" />
              </div>
              <div class="col-sm-6 col-md-3 col-lg-3 col-xl-2 my-1">
                {{ $t("message.price") }} :
                <br />
                <b-form-input v-model="searchData.cost" />
              </div>
              <div class="col-sm-6 col-md-3 col-lg-2 col-xl-2 my-1">
                <br />
                <b-btn variant="primary" type="submit">
                  <i class="fa fa-search"></i>
                  &nbsp;{{ $t("message.search") }}
                </b-btn>
              </div>
            </div>
          </b-form>
          <br />
          <div class="table-responsive">
            <table class="table-striped table2">
              <thead class="bg-primary">
                <tr>
                  <th>{{ $t("message.productId") }}</th>
                  <th>{{ $t("message.sku") }}</th>
                  <th>{{ $t("message.productName") }}</th>
                  <th>{{ $t("message.description") }}</th>
                  <th>{{ $t("message.price") }}</th>
                  <th>{{ $t("message.cost") }}</th>
                  <th>{{ $t("message.image") }}</th>
                  <template>
                    <th>Action</th>
                  </template>
                </tr>
              </thead>
              <tbody>
                <template v-for="item in rowData">
                  <tr>
                    <td style="text-align: center">{{item.productId}}</td>
                    <td>{{item.sku}}</td>
                    <td>{{item.name}}</td>
                    <td>{{item.description}}</td>
                    <td style="text-align: center">{{item.cost}}</td>
                    <td style="text-align: center">{{item.stdPrice}}</td>
                    <td>
                      <p class="img" @click="showImage(item)" style="width: 100px;">show</p>
                    </td>
                    <td>
                      <b-btn class="ml-1 mr-1 my-1" size="sm" style="width: 50px;" variant="warning" @click="editData(item)" :disabled="!isAdmin(role_id)">{{ $t("message.edit") }}</b-btn>
                      <b-btn class="ml-1 mr-1" size="sm" style="width: 50px; background-color: red;" variant="danger" :disabled="true">{{ $t("message.delete") }}</b-btn>
                    </td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
          <br />

          <b-pagination size="md" :total-rows="totalRows" v-model="pagination" :per-page="itemperPage" @input="pageChanged(pagination)"></b-pagination>
          <!--          <uib-pagination :total-items=totalRows :items-per-page=itemperPage v-model="pagination" class="pagination-sm" @change="pageChanged(pagination.currentPage)"></uib-pagination>-->
        </div>
      </div>
      <b-modal ref="imageModal" size="sm" hide-header hide-footer @hide="closeImage()">
        <img class="img" :src="imageModalData" />
      </b-modal>
    </b-card>
  </div>
</template>

<script>
import webServices from '../../script'
import { DOMAIN } from '../../app.config'
export default {
  name: 'Products',
  data() {
    return {
      role_id: window.localStorage.getItem('roleID'),
      totalRows: 1,
      itemperPage: 20,
      pagination: 1,
      rowData: [],
      ModalData: {},
      searchData: {
        page: 1,
        rows: 20
      },
      imageModalData: '',
      selectedProduct: [],
      machineType: {},
      productType: [
        {
          name: 'ทั้งหมด',
          value: ''
        },
        {
          name: 'น้ำกระป๋อง',
          value: 'can'
        },
        {
          name: 'ขนม',
          value: 'snack'
        },
        {
          name: 'เครื่องซักผ้า',
          value: 'washer'
        },
        {
          name: 'เครื่องอบผ้า',
          value: 'dryer'
        },
        {
          name: 'น้ำยาปรับผ้านุ่ม',
          value: 'softener'
        },
        {
          name: 'ผงซักฟอก',
          value: 'detergent'
        }
      ]
    }
  },
  methods: {
    isAdmin(role) {
      return webServices.isAdmin(role)
    },
    editData(data) {
      this.$router.push({ path: `/product/edit/${data.productId}` })
    },
    showImage(data) {
      if (data.image !== null) {
        this.imageModalData = DOMAIN + '/' + data.image
      } else {
        this.imageModalData = DOMAIN + '/public/images/products/tempproductimg.jpg'
      }

      this.$refs.imageModal.show()
    },
    closeImage() {
      // this.imageModalData = ''
    },
    newProduct() {
      this.$router.push('product/create')
    },
    getProduct(data) {
      this.$Progress.start()
      webServices
        .getProduct(data)
        .then(res => {
          // console.log(res.data)
          this.$Progress.finish()
          this.rowData = res.data.rows
          // for (var i = 0; i < res.data.rows.length; i++) {
          //     if (res.data.rows[i].image !== null) {
          //         // server
          //         this.rowData[i].image = DOMAIN + '/' + res.data.rows[i].image
          //     } else {
          //         this.rowData[i].image = DOMAIN + '/public/images/products/tempproductimg.jpg'
          //     }
          // }
          this.totalRows = res.data.count
        })
        .catch(err => {
          this.$Progress.fail()
          if (err.response.status === 401) {
            webServices.tokenExpire()
          }
          const errorText = {
            error: err.response.data,
            code: err.response.status,
            text: err.response.statusText
          }
          this.$toast.error({
            title: 'ERROR',
            message: JSON.stringify(errorText)
          })
        })
    },
    searchFn() {
      // console.log(this.searchData)
      this.getProduct(this.searchData)
    },
    pageChanged(PageNum) {
      window.scrollTo(0, 0)
      this.searchData.page = PageNum
      this.searchFn()
    }
  },
  mounted() {
    this.getProduct(this.searchData)
  }
}
</script>
<style>
.table2 {
  width: 100%;
  border: none;
}
.table2 td,
.table2 th {
  padding: 8px;
}
.img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 80%;
}
.img {
  text-decoration: none;
  color: #008cba;
}
p {
  text-decoration: underline;
  color: skyblue;
  cursor: pointer;
}
</style>
